<script>
import appConfig from "../../../app.config";
import NavMenu from "../landing/inc/nav-menu.vue";
import Footer from "../landing/inc/footer.vue";
import {
  required,
  email,
  helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axios from 'axios';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  page: {
    title: "Register",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  components: {
    NavMenu,
    Footer
  },
  data() {
    return {
      mode : 'form',
      options : {
        shop_types : [{'code' : 'general', 'name' : '일반쇼핑몰'},
                      {'code' : 'agency', 'name' : '인쇄기획사'},
                      {'code' : 'digital', 'name' : '디지털인쇄소'},
                      {'code' : 'offset', 'name' : '옵셋인쇄소'},
                      {'code' : 'total', 'name' : '종합인쇄소'}],
        estimate_modules : [{'code' : 'businesscard', 'name' : '명함', 
                                    items : [{'code' : 'basic','name' : '일반/수입지명함'},{'code' : 'card','name' : '카드명함'}]},
                            {'code' : 'flyer', 'name' : '전단지', 
                                    items : [{'code' : 'general','name' : '일반전단지'},{'code' : 'premium','name' : '고급전단지'}]},
                            {'code' : 'sticker', 'name' : '스티커', 
                                    items : [{'code' : 'cutting','name' : '재단스티커'},{'code' : 'circle','name' : '원형스티커'},{'code' : 'tomson','name' : '모양스티커'}]},
                           ],
        postpress_types : [{'code' : 'foiling', 'name' : '박' },
                           {'code' : 'pressing', 'name' : '형압'},
                           {'code' : 'scoring', 'name' : '오시'}]
      },
      form: {
        shop_type : 'agency',
        is_design : false,
        is_otherestimate : false,
        is_webhard : false,
        is_correction : false,
        is_pg : false,
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations: {
    form: {
      name : {
        required: helpers.withMessage("이름을 입력해주세요.", required),
      },
      email : {
        required: helpers.withMessage("이메일을 입력해주세요.", required),
        email: helpers.withMessage("잘못된 이메일 형식입니다.", email),
      },
      phone : {
        required: helpers.withMessage("연락처를 입력해주세요.", required),
      },
      content : {
        required: helpers.withMessage("문의내용을 입력해주세요.", required),
      }
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {

    async tryToRegisterIn() {
      let the = this;
      this.submitted = true;

      this.v$.$touch();

      axios.defaults.baseURL = process.env.VUE_APP_API_URL;
      axios.post('/contactus/store', {
        name : this.form.name,
        company : this.form.company,
        phone : this.form.phone,
        email : this.form.email,
        content : this.form.content,
        type : this.form.type
      }).then((response) => {

        if (response.data.status == 'success') {
            this.mode = 'result';
        }

      }).catch(function (error) {
        if (error.response) {

            //if(error.response.data.errors.length > 0) {
                the.isRegisterError = true;
                return the.regError = error.response.data.message;
            //}

            //console.log('error response', error.response.data);
        }
        else {
            console.log('error message', error.message);
        }
        
      })
    },
    isData() {
        const plus = document.getElementsByClassName("plus");
        const minus = document.getElementsByClassName("minus");
  
        if (plus) {
          Array.prototype.forEach.call(plus, (e) => {
            e.addEventListener("click", (event) => {
              let par = event.target.closest(".input-step");
              par.getElementsByClassName("product-quantity")[0].value++;
            });
          });
        }
  
        if (minus) {
          Array.prototype.forEach.call(minus, (e) => {
            e.addEventListener("click", (event) => {
              let par = event.target.closest(".input-step");
              if (par.getElementsByClassName("product-quantity")[0].value > 0)
                par.getElementsByClassName("product-quantity")[0].value--;
            });
          });
        }
      },
  },

  mounted() {
    setTimeout(() => {
        this.isData();
    }, 100);

  }

};
</script>
<template>
    <div class="layout-wrapper">

        <nav-menu></nav-menu>

        <div class="bg-overlay bg-overlay-pattern"></div>

        <section class="section header">
            <div class="bg-overlay"></div>
        </section>

        <section class="section">
            <b-container>

                <b-row class="justify-content-center">
                    <b-col lg="12">
                        <div class="text-center pb-2">
                        <h4 class="fw-semibold fs-22">
                            다온코드 맞춤견적
                        </h4>
                        <p class="text-muted fs-15">
                            내 사업에 맞는 기능만을 선택하여 합리적인 가격으로 쇼핑몰을 구축하세요.
                        </p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="justify-content-center">
                    <b-col md="12" lg="10" xl="8">

                        <b-card no-body class="mt-4" v-if="mode == 'form'">
                            <b-card-header>
                                <h4 class="fw-semibold fs-22">다온샵</h4>
                            </b-card-header>
                            <b-card-body class="p-4">
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">문의내용은 상세하게 작성할수록 상담에 많은 도움이 됩니다.</h5>
                                </div>
                                <div class="p-2 mt-4">

                                    <form class="needs-validation" @submit.prevent="tryToRegisterIn">
                                        <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>개발문의 성공.</b-alert>

                                        <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                                        </b-alert>

                                        <div v-if="notification.message" :class="'alert ' + notification.type">
                                        {{ notification.message }}
                                        </div>

                                        <div class="mb-2 border-top">
                                            <b-row class="mt-2">
                                                <b-col cols="3">쇼핑몰유형 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">
                                                    
                                                    <b-row>
                                                        <b-col cols="4">
                                                            <select class="form-select" v-model="form.shop_type">
                                                                <option v-for="(item, index) of options.shop_types" :key="index" :value="item.code">{{item.name}}</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col cols="8">

                                                        </b-col>
                                                    </b-row>

                                                </b-col>
                                            </b-row>
                                        </div>

                                        <div class="mb-2 border-top border-top-dashed">
                                            <b-row class="mt-2 mb-2">
                                                <b-col cols="3">견적모듈 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <b-row v-for="(est, code) of options.estimate_modules" :key="code">
                                                        <b-col cols="4">
                                                            <div class="form-check form-switch form-switch-md" dir="ltr">
                                                                <input type="checkbox" class="form-check-input">
                                                                <label class="form-check-label">{{ est.name }}</label>
                                                            </div>
                                                        </b-col>
                                                        <b-col cols="8">
                                                            <div class="form-check form-check-inline" v-for="(item, index) of est.items" :key="index">
                                                                <input class="form-check-input" type="checkbox">
                                                                <label class="form-check-label">
                                                                {{ item.name }}
                                                                </label>
                                                            </div>
                                                        </b-col>
                                                    </b-row>                                                                             
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="mb-2 border-top border-top-dashed">
                                            <b-row class="mt-2 mb-2">
                                                <b-col cols="3">후가공 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                <div class="form-check form-check-inline" v-for="(pp, code) of options.postpress_types" :key="code">
                                                    <input class="form-check-input" type="checkbox">
                                                    <label class="form-check-label">{{ pp.name }}</label>
                                                </div>

                                                </b-col>
                                            </b-row>

                                        </div>
                                        <div class="mb-2 border-top border-top-dashed">
                                            <b-row class="mt-2 mb-2">
                                                <b-col cols="3">디자인옵션 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <div class="form-check form-check-inline mb-2">
                                                        <input class="form-check-input" type="radio" v-model="form.is_design"  value="false">
                                                        <label class="form-check-label" >사용안함</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" v-model="form.is_design" value="true">
                                                        <label class="form-check-label">사용함</label>
                                                    </div>

                                                </b-col>
                                            </b-row>

                                        </div>
                                        <div class="mb-2 border-top border-top-dashed">
                                            <b-row class="mt-2 mb-2">
                                                <b-col cols="3">별도견적 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <div class="form-check form-check-inline mb-2">
                                                        <input class="form-check-input" type="radio" v-model="form.is_otherestimate"  value="false">
                                                        <label class="form-check-label">사용안함</label>
                                                    </div>

                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" v-model="form.is_otherestimate"  value="true">
                                                        <label class="form-check-label">사용함</label>
                                                    </div>

                                                </b-col>
                                            </b-row>

                                        </div>
                                        <div class="mb-2 border-top border-top-dashed">
                                            <b-row class="mt-2 mb-2">
                                                <b-col cols="3">웹하드 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                <div class="form-check form-check-inline mb-2">
                                                    <input class="form-check-input" type="radio" v-model="form.is_webhard"  value="false">
                                                    <label class="form-check-label">사용안함</label>
                                                </div>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" v-model="form.is_webhard"  value="true">
                                                    <label class="form-check-label">사용함</label>
                                                </div>


                                                </b-col>
                                            </b-row>

                                        </div>
                                        <div class="mb-2 border-top border-top-dashed">
                                            <b-row class="mt-2 mb-2">
                                                <b-col cols="3">교정시스템 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <div class="form-check form-check-inline mb-2">
                                                        <input class="form-check-input" type="radio" v-model="form.is_webhard"  value="false">
                                                        <label class="form-check-label">사용안함</label>
                                                    </div>

                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" v-model="form.is_webhard"  value="true">
                                                        <label class="form-check-label">사용함</label>
                                                    </div>

                                                </b-col>
                                            </b-row>

                                        </div>
                                        <div class="mb-2 border-top border-top-dashed">
                                            <b-row class="mt-2 mb-2">
                                                <b-col cols="3">PG결제 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <div class="form-check form-check-inline mb-2">
                                                        <input class="form-check-input" type="radio" v-model="form.is_pg"  value="false">
                                                        <label class="form-check-label">사용안함</label>
                                                    </div>

                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"  v-model="form.is_pg"  value="true">
                                                        <label class="form-check-label">사용함</label>
                                                    </div>

                                                </b-col>
                                            </b-row>

                                        </div>
                                        <div class="mb-2 border-top border-top-dashed">
                                            <b-row class="mt-2 mb-2">
                                                <b-col cols="3">추가페이지 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">
                                                    
                                                    <b-row class="mb-2">
                                                        <b-col cols="4">
                                                            <select class="form-select" v-model="form.type">
                                                                <option value="general" selected>메인페이지</option>
                                                                <option value="agency">서브페이지</option>
                                                                <option value="digital">컨텐츠페이지</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col cols="6">
                                                            <div class="input-step">
                                                            <button type="button" class="minus shadow">–</button>
                                                            <input
                                                                type="number"
                                                                class="product-quantity"
                                                                value="2"
                                                                min="0"
                                                                max="100"
                                                                readonly
                                                            />
                                                            <button type="button" class="plus shadow">+</button>
                                                            </div>

                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mb-2">
                                                        <b-col cols="4">
                                                            <select class="form-select" v-model="form.type">
                                                                <option value="general" selected>메인페이지</option>
                                                                <option value="agency">서브페이지</option>
                                                                <option value="digital">컨텐츠페이지</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col cols="6">
                                                            <div class="input-step">
                                                            <button type="button" class="minus shadow">–</button>
                                                            <input
                                                                type="number"
                                                                class="product-quantity"
                                                                value="2"
                                                                min="0"
                                                                max="100"
                                                                readonly
                                                            />
                                                            <button type="button" class="plus shadow">+</button>
                                                            </div>

                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mb-2">
                                                        <b-col cols="4">
                                                            <select class="form-select" v-model="form.type">
                                                                <option value="general" selected>메인페이지</option>
                                                                <option value="agency">서브페이지</option>
                                                                <option value="digital">컨텐츠페이지</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col cols="6">
                                                            <div class="input-step">
                                                            <button type="button" class="minus shadow">–</button>
                                                            <input
                                                                type="number"
                                                                class="product-quantity"
                                                                value="2"
                                                                min="0"
                                                                max="100"
                                                                readonly
                                                            />
                                                            <button type="button" class="plus shadow">+</button>
                                                            </div>

                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>

                                        </div>

                                    </form>

                                </div>
                            </b-card-body>

                        </b-card>

                        <b-card no-body class="mt-4" v-if="mode == 'result'">

                            <b-card-body class="p-4">
                                <div class="p-2 mt-4">
                                    <div class="text-center mt-2">
                                        <h5 class="text-primary">성공적으로 문의가 등록되었습니다.</h5>
                                        <h5 class="text-primary">최대한 빠른 시간내에 연락드리도록 하겠습니다.</h5>
                                        <h5 class="text-primary mt-3">감사합니다.</h5>
                                    </div>
                                </div>
                            </b-card-body>

                        </b-card>

                    </b-col>

                    <b-col md="12" lg="10" xl="4">

                        <b-card no-body class="mt-4" v-if="mode == 'form'">

                            <b-card-header>
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">개발 견적서</h5>
                                </div>
                            </b-card-header>
                            <b-card-body>

                                <div class="w-100 p-1">

                                    <b-row class="align-middle mb-2">
                                        <b-col cols="3">명함</b-col>
                                        <b-col cols="9">

                                            <b-row>
                                                <b-col cols="6">견적모듈</b-col>
                                                <b-col cols="6" class="text-end">200,000 원</b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col cols="6">품목 3개</b-col>
                                                <b-col cols="6" class="text-end">150,000 원</b-col>
                                            </b-row>

                                        </b-col>
                                    </b-row>
                                    <b-row class="align-middle pt-2 mb-2 border-top border-top-dashed">
                                        <b-col cols="3">명함</b-col>
                                        <b-col cols="9">

                                            <b-row>
                                                <b-col cols="6">견적모듈</b-col>
                                                <b-col cols="6" class="text-end">200,000 원</b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col cols="6">품목 3개</b-col>
                                                <b-col cols="6" class="text-end">150,000 원</b-col>
                                            </b-row>

                                        </b-col>
                                    </b-row>
                                    <b-row class="align-middle pt-2 mb-2 border-top border-top-dashed">
                                        <b-col cols="3">스티커</b-col>
                                        <b-col cols="9">

                                            <b-row>
                                                <b-col cols="6">견적모듈</b-col>
                                                <b-col cols="6" class="text-end">200,000 원</b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col cols="6">품목 3개</b-col>
                                                <b-col cols="6" class="text-end">150,000 원</b-col>
                                            </b-row>

                                        </b-col>
                                    </b-row>
                                    <b-row class="align-middle pt-2 mb-2 border-top border-top-dashed">
                                        <b-col cols="3">합계</b-col>
                                        <b-col cols="9">

                                            <b-row>
                                                <b-col cols="6">공급가</b-col>
                                                <b-col cols="6" class="fw-semibold text-end text-info">200,000 원</b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col cols="6">부가세</b-col>
                                                <b-col cols="6" class="text-end">150,000 원</b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col cols="6">결제액</b-col>
                                                <b-col cols="6" class="fw-bold fs-15 text-end text-danger">150,000 원</b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="text-center border-top">

                                    <div class="p-2 mt-4">
                                        <b-button variant="danger" size="lg" class="waves-effect waves-light" type="submit">문의하기</b-button>
                                    </div>

                                    <div class="mt-4 text-center">
                                        <div class="signin-other-title">
                                            <h5 class="fs-13 mb-4 title text-muted">
                                                <p class="text-muted">최선을 다해 개발상담해드리겠습니다.</p>
                                                <p class="text-muted">감사합니다.</p>
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                            </b-card-body>

                        </b-card>

                    </b-col>

                </b-row>

            </b-container>
        </section>
        <Footer></Footer>
    </div>
</template>